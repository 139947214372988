import React from "react";
import "./FooterBottom.css";
import logo from "../../assets/Logo.png";
import Wave from "react-wavify";
import map from "../../assets/map.jpeg"
const FooterBottom = () => {
  return (
    <div>
      <Wave
        fill="#ba3030"
        paused={false}
        options={{
          height: 80,
          amplitude: 20,
          speed: 0.15,
          points: 3,
        }}
      />
      <footer>
        <div className="container mt-5">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-6 col-lg-3 about-footer ">
                <img src={logo} style={{ width: "60px", height: "60px" }} />
                <ul>
                  <li>
                    <a href="+92 340 8618145">
                      <i className="fas fa-phone fa-flip-horizontal"></i>+92 340
                      8618145
                    </a>
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                    Office 11, 2nd Floor, Metro Station, Mid City Shopping Mall,
                    near Rehman Abad, B-Block Block B Satellite Town,
                    Rawalpindi, Punjab 48061
                  </li>
                  <p className="d-flex m-0">
                    <i className="fas fa-envelope mr-2 mt-0"></i>
                    hr@mksmarttechnologies.com
                  </p>
                </ul>
              </div>
              

              
              <div className="col-md-6 col-lg-4 open-hours">
                <div className="footer-title mt-3">
                  <h4>Open hours</h4>
                 
                </div>
                <table className="table text-white">
                  <tbody>
                    <tr>
                      <td>
                        <i className="far fa-clock"></i>Monday
                      </td>
                      <td>9:00am - 06:00pm</td>
                    </tr>
                    <tr>
                      <td>
                        <i className="far fa-clock"></i>Tuesday
                      </td>
                      <td>9:00am - 06:00pm</td>
                    </tr>
                    <tr>
                      <td>
                        <i className="far fa-clock"></i>Wednesday
                      </td>
                      <td>9:00am - 06:00pm</td>
                    </tr>
                    <tr>
                      <td>
                        <i className="far fa-clock"></i>Thursday
                      </td>
                      <td>9:00am - 06:00pm</td>
                    </tr>
                    <tr>
                      <td>
                        <i className="far fa-clock"></i>Friday
                      </td>
                      <td>9:00am - 06:00pm</td>
                    </tr>
                  </tbody>
                </table>
               
                
              </div><div className="col-md-6 col-lg-4 open-hours">
              
              <div className="footer-title mb-4">
              <i className="fa fa-map-marker " style={{fontSize:'2rem', color:'white'}}></i>
                
              </div>
              <div className='mt-5 '>
              <iframe className="ml-5 " src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.844836537034!2d73.07101787431729!3d33.63526497331677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df94d9ade0698b%3A0xe1b6346b80ff05f6!2sMid%20City%20Shopping%20Mall%2C%20B-Block%20Block%20B%20Satellite%20Town%2C%20Rawalpindi%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1687429022237!5m2!1sen!2s" width="300" height="200" style={{border:'2px solid red', allowfullscreen:'',loading:'lazy',referrerpolicy:'no-referrer-when-downgrade',marginTop:'-1rem'}}></iframe>
              </div>
              
              
            </div>
            </div>
          </div>
          <hr className="bg-danger" />
        </div>
      </footer>
    </div>
  );
};

export default FooterBottom;
