import React from 'react'
import './Contact.css'
const Contact = () => {
  return (
    <>
    <div class="site-heading text-center">
    <p className="mt-5">
      CONTACT <span> US </span>
    </p>

  </div>
    <div className="container">
      <div className="row">
        <div className="col-md">
          <div className="contact shadow">
            <h4><i className="fa fa-whatsapp"  style={{width:'3rem', height:'6.5rem', fontSize:'2rem',}}></i><br/>PHONE: <br/><a href="#">+92 340 8618145</a></h4>
          </div>
        </div>
        <div className="col-md">
          <div className="contact shadow">
            <h4><i className="far fa-envelope"  style={{width:'3rem', height:'6.5rem', fontSize:'2rem',}}></i><br/>E-MAIL: <br/><a href="#">hr@mksmarttechnologies.com</a></h4>
          </div>
        </div>
        <div className="col-md">
          <div className="contact shadow">
            <h4><i className=" fa fa-address-card " style={{width:'3rem', height:'4rem', fontSize:'2rem',}}></i><br/>ADDRESS: <br/><a href="#">Office 11, 2nd Floor, Mid City Shopping Mall, near Rehman Abad Metro Station,Main Murree Road Rawalpindi, Punjab 48061</a></h4>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Contact