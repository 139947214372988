import { Col, Row } from "antd";
import React from "react";
import "./Technology.css";
import kotlin from "../../assets/kotlin.svg";
import javalogo from "../../assets/javalogo.svg";
import swift from "../../assets/swift.svg";
import node from "../../assets/node.svg";
import flutterlogo from "../../assets/flutterlogo.svg";
import adobe from "../../assets/adobe.svg";
import jetpack from "../../assets/jetpack.png";
import { Container } from "react-bootstrap";

const techImg = [
  {
    img: kotlin,
    text: "kotlin",
  },
  {
    img: javalogo,
    text: "java",
  },
  {
    img: swift,
    text: "swift",
  },
  {
    img: node,
    text: "node js",
  },
  {
    img: jetpack,
    text: "jetpacks",
  },
  {
    img: adobe,
    text: "adobe",
  },
];

const text = " We explore the dynamic landscape of Mobile App Development";
const Technology = () => {
  return (
    <>
      <Container>
        <div className="tech__portion">
          <div class="site-heading text-center">
            <p className="mt-5">
              OUR<span> TECHNOLOGIES </span>
            </p>
            <h4>{text}</h4>
          </div>
          <Row className="mb-5">
            {techImg.map((item) => {
              return (
                <>
                  <Col xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
                    <img src={item.img} />
                    <h4>{item.text}</h4>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Technology;
