import React from "react";
import "./Service.css";
import arprojects from "../../assets/arprojects.svg";
import trackingapps from "../../assets/trackingapps.svg";
import mobileappdevelopment from "../../assets/mobileappdevelopent.svg";
import webdevelopment from "../../assets/webdevelopment.svg";
import graphicaldesigning from "../../assets/graphicaldesigning.svg";
import qualityassurance from "../../assets/qualityassurance.svg";
import { Container } from "react-bootstrap";
import { Row } from "antd";

const servicesData = [
  {
    img: trackingapps,
    title: "Tracking Apps",
    text: "We have developed a cutting-edge GPS location tracking mobile app for both Android and iOS platforms. Our app utilizes the power of GPS technology to accurately track the real-time location of users Our app offers a user-friendly interface, making it intuitive and easy to navigate. Users can create customized geofences, defining specific areas on the map where they want to receive alerts when someone enters or exits those boundaries. Additionally,In our app provides a vast history log that allows users to review past locations and routes.",
  },
  {
    img: mobileappdevelopment,
    title: "Mobile App Development",
    text: " We specialize in providing cutting-edge mobile app development services that help businesses thrive in the digital landscape. Our team of skilled developers and designers is passionate  about creating innovative and user-friendly mobile applications tailored to meet your unique  business requirements.We excel in building custom mobile applications from scratch, leveraging   the latest technologies and industry best practices. Whether you need a native iOS or Android app  or a cross-platform solution, our experts will work closely with you to understand ",
  },
  {
    img: webdevelopment,
    title: "Web Development",
    text: "  We specialize in creating custom web solutions tailored to meet your unique business requirements. Our experienced developers leverage the latest technologies and  to build robust and scalable websites. you need  e-commerce platform, or web application, we can transform your  vision into a fully functional and feature-rich online presence.In todays mobile-driven world, having a responsive website is essential. Our web developers ensure that your website   looks and functions flawlessly across different devices, screen sizes, and browsers.",
  },
  {
    img: arprojects,
    title: "AR Porjects",
    text: "MK Smart Technologies Revolutionizing Augmented Reality Experiences on Your Mobile Device At MK Smart Technologies, we are passionate about transforming the way you interact with the world through cutting-edge Augmented Reality mobile apps. Our expert team of developers and designers harness the power of AR technology to create immersive and captivating experiences  Our apps seamlessly integrate with your mobile device Our team works closely with you to deliver tailor-made AR solutions that align with your brand and goals.",
  },
  {
    img: graphicaldesigning,
    title: "UI/UX Design",
    text: " Our UI/UX design services combine creativity, user research, and industry best practices to craftvisually stunning and intuitive interfaces that captivate users and drive engagement. We prioritize the seamless fusion of aesthetics and functionality to deliver exceptional user experiences across  web and mobile platforms.We believe that a well-designed user interface is crucial for the success of any mobile app. Our talented UI/UX designers combine aesthetics with functionality to create visually appealing  and intuitive interfaces that enhance user engagement and satisfaction.",
  },
  {
    img: qualityassurance,
    title: "QA & Software Testing",
    text: "   We recognize the critical importance of quality assurance and software testing in ensuring the reliability, functionality, and performance of your digital products. Our QA and software testing services are designed to identify any  issues,  ensuring that your software meets the highest standards of quality and provides a seamless user  experience. Our experienced QA professionals work closely with your team to understand the software   functionality , we create a comprehensive test plan that encompasses all necessary testing activities",
  },
];
const Service = () => {
  return (
    <section className="we-offer-area text-center bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="site-heading text-center">
              <p>
                OUR <span> SERVICES </span>
              </p>
              <h4>The Services We Offer Are ...</h4>
            </div>
          </div>
        </div>
        <div className="row our-offer-items less-carousel">
          {servicesData.map((item) => {
            return (
              <div className="col-md-4 col-sm-6 equal-height">
                <div className="item">
                  <div className="hover-area">
                    <img src={item.img} />
                  </div>
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                 
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Service;

// import React from 'react'
// import './Service.css'
// const Service = () => {
//   return (
//     <div>

//     <div class="main flex">
//        <h1>Animated Card</h1>
//        <section class="flex">
//          <article class="flex">
//            <h2>Heading</h2>
//            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat consectetur delectus eligendi quasi esse commodi in quod, nesciunt laboriosam quae voluptatibus enim voluptatem saepe nulla cum, culpa ipsa repellendus at.</p>
//             <button class="btn">Buy Now</button>
//             <div class="bgColor"></div>
//          </article>
//          <article class="flex">
//            <h2>Heading</h2>
//            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat consectetur delectus eligendi quasi esse commodi in quod, nesciunt laboriosam quae voluptatibus enim voluptatem saepe nulla cum, culpa ipsa repellendus at.</p>
//             <button class="btn">Buy Now</button>
//             <div class="bgColor"></div>
//          </article>
//          <article class="flex">
//            <h2>Heading</h2>
//            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat consectetur delectus eligendi quasi esse commodi in quod, nesciunt laboriosam quae voluptatibus enim voluptatem saepe nulla cum, culpa ipsa repellendus at.</p>
//             <button class="btn">Buy Now</button>
//             <div class="bgColor"></div>
//          </article>
//        </section>
//     </div>

//     <footer>
//        <p class="myCopy">All designed by<span>Fillipe Pascoall</span></p>
//     </footer>

//     </div>
//   )
// }

// export default Service
